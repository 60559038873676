import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import ColorSchemeProvider from "./components/shared/color-scheme-provider/ColorSchemeProvider";
import {THEME} from "./constants/theme";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ColorSchemeProvider colors={THEME}>
                <App/>
            </ColorSchemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
