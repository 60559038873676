import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {useLocation} from "react-router-dom";
import Contact from "./contact/Contact";
import Project from "./project/Project";
import Profile from "./profile/Profile";
import {BusinessCard} from "../../types/types";
import {getData} from "../../api/data/get";
import {getUserFromRoute} from "../../utils/route";
import {getContactIcon, getContactLink, getTitle} from "../../utils/contact";
import "./homePage.scss"
import WaitCursor from "../shared/wait-cursor/WaitCursor";

const HomePage = () => {
    const [data, setData] = useState<BusinessCard>()
    const [state, setState] = useState<'none' | 'pending' | 'successful' | 'rejected'>('none')

    const location = useLocation();

    useEffect(() => {
        const loadData = async () => {
            setState('pending')

            const {status, data} = await getData(getUserFromRoute(location.pathname));

            if (status === 200 && data) {
                setData(data)
                setState('successful')
            } else {
                setState('rejected')
            }
        }

        void loadData();
    }, [location.pathname]);

    useEffect(() => {
        if(data?.user.firstName){
            document.title = data?.user.firstName;
        }
    }, [data?.user.firstName]);

    const contactItems = useMemo(() => {
        if (!data?.contact) {
            return;
        }

        const items: ReactElement[] = []

        for (const [key, value] of Object.entries(data.contact)) {
            const icon = getContactIcon(key);
            const link = getContactLink(key, value);
            const title = getTitle(key);

            if (!icon || !link || !title) {
                return;
            }

            items.push(
                <Contact key={key} title={title} isMail={key === 'mail'} icon={icon} link={link}/>
            );
        }

        return items;
    }, [data])

    const projectItems = useMemo(() => {
        if (!data?.projects) {
            return;
        }

        const items: ReactElement[] = [];

        data.projects.forEach(({url, id, name, links}) => {
            items.push(<Project key={id} name={name} url={url} links={links}/>)
        })

        return items
    }, [data])

    return (
        <div className="home-page">
            {
                state === 'successful' && <>
                    <Profile image={data?.user.image ?? ''} firstName={data?.user.firstName ?? ''}
                             lastName={data?.user.lastName ?? ''}/>
                    {data?.contact && <><h3>Contact</h3>
                        <div className="home-page__contact">{contactItems}</div>
                    </>
                    }
                    {data?.projects && data?.projects?.length > 0 && <><h3>Projects</h3>
                        <div className="home-page__projects">{projectItems}</div>
                    </>}
                </>
            }
            {state === 'rejected' &&
                <div className="home-page__error">Beim Laden der Daten ist ein Fehler aufgetreten!</div>}
            <WaitCursor shouldShowWaitCursor={state === 'pending'}/>
        </div>
    );
}

HomePage.displayName = 'HomePage';

export default HomePage;
